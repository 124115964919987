<template>
  <v-dialog v-model="dialog" persistent width="80%">
    <v-card :light="true" color="white">
      <v-overlay class="ml-3" :value="overlayLocation" absolute color="white">
        <v-progress-circular indeterminate size="64" color="primary" />
      </v-overlay>
      <v-card-title class="headline pa-6"> {{ $t("authority.titles.authority_locations") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-space-between align-center align-content-center pa-6">
        <!--<LocationsTable :edited-item="editedItem" />-->
        <v-card outlined class="mx-1" width="720px" max-width="100%">
          <v-card-title  class="px-6 d-flex justify-space-between align-center align-content-center">
            <h4 class="headline">{{ $t('authority.titles.grant') }}</h4>
            <h4 class="headline">{{ $t('authority.titles.selected') }}: {{ editedItem.grant ? editedItem.grant.length : 0 }}</h4>
          </v-card-title>
          <v-card-text>
            <StoragesTable item-key="storageDeviceId" :disabled="editedItem.revoke && editedItem.revoke.length > 0" :edited-item="editedItem" :revoke="true" @update:selected="$set(editedItem, 'grant', $event)" />
          </v-card-text>
        </v-card>
        <v-card elevation="0">
          <v-card-text class="d-flex flex-column align-center align-content-center">
            <v-btn  class="elevation-0 my-1" color="primary" @click="assignLocations" :disabled="disabled || editedItem.revoke.length > 0">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn  class="elevation-0 my-1" color="error" @click="assignLocations" :disabled="disabled || editedItem.grant.length > 0">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card outlined class="mx-1" width="720px" max-width="100%">
          <v-card-title class="px-6 d-flex justify-space-between align-center align-content-center">
            <h4 class="headline">{{ $t('authority.titles.revoke') }}</h4>
            <h4 class="headline">{{ $t('authority.titles.selected') }}: {{ editedItem.revoke ? editedItem.revoke.length : 0 }}</h4>
          </v-card-title>
          <v-card-text>
            <StoragesTable item-key="storageDeviceId" :disabled="editedItem.grant && editedItem.grant.length > 0" :edited-item="editedItem" :grant="true" @update:selected="$set(editedItem, 'revoke', $event)" />
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn
          small elevation="0" color="primary" class="t-bw-primary--text"
          @click="closeAuthorityLocations"
        >
          {{ $t("general.buttons.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import _ from "lodash"
  import { mapActions, mapGetters } from "vuex"
  import StoragesTable from "@/module/configuration/authority/components/ListStoragesTable"

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    name: "GrantAccessLocationComponent",
    components: { StoragesTable },
    props: {
      dialog: {
        type: Boolean,
        require: true,
        default: false,
      },
      editedItem: {
        type: Object,
        require: true,
      },
    },

    data: () => ({
      loadings: {
        location: false,
        storage: false,
      },
      overlayLocation: false,
      searchStorage: null,
      defaultFilters: {
        companyId: PROFILE.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: "id",
          direction: "DESC",
        },
      },
    }),

    computed: {
      ...mapGetters({
        storages: "storage/storagesByUserAccess",
        totalStoragesFromServer: "storage/totalFromServer",
      }),
      disabled() {
        return !((this.editedItem.grant && this.editedItem.grant.length > 0) || (this.editedItem.revoke && this.editedItem.revoke.length > 0))
      },
    },
    methods: {
      ...mapActions({
        fetchAccessStoragesByUser: "storage/fetchAccessStoragesByUser",
        fetchListLocations: "locations/fetchFilteredLocations",
        fetchListStorages: "storage/fetchFilteredStorages",
        pushLocations: "authority/pushLocations",
      }),
      async assignLocations() {
        this.overlayLocation = true
        let item = Object.assign({}, this.editedItem)

        const data = {
          grant: _.map(item.grant, (g) => { return g.storageDeviceId }),
          revoke: _.map(item.revoke, (r) => { return r.storageDeviceId }),
          personInChargeId: this.editedItem.id
        }

        await this.pushLocations([data, this.$toast]).finally(() => {
          this.overlayLocation = false
          this.$root.$emit("load:storages", item)
          this.$root.$emit("clear:locations")
          this.$set(this.editedItem, 'grant', [])
          this.$set(this.editedItem, 'revoke', [])
        })
      },
      closeAuthorityLocations() {
        this.$root.$emit("clear:locations")
        this.$emit("dialog:action", false)
        this.$set(this.editedItem, 'grant', [])
        this.$set(this.editedItem, 'revoke', [])
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
    },
    destroyed() {
      this.$root.$off("load:storages")
      this.$root.$off("clear:locations")
    }
  }
</script>

<style scoped>
.border__left {
  border-left: 10px solid;
}
.border__right {
  border-right: 10px solid;
}

.border__green {
  border-color: #3de94b;
  background-color: #6dff741e;
}

.border__red {
  border-color: #ff0c0c;
  background-color: #ff6d6d1e;
}

</style>
